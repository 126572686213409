/* Large devices (large desktops, 1200px and up) */
@media (max-width: $large-devices) {
	/*------------------------------------------------------------------
		[ HEADER ]
	-------------------------------------------------------------------*/
    #top-header .navbar .navbar-collapse #menu-top-menu > li > a{
        padding: 0px 13px;
    }
    #top-header .top_nav .navbar-brand {
      padding: 25px 0 20px;
    }
    
	/*------------------------------------------------------------------
		[ CONTENT ]
	-------------------------------------------------------------------*/
	// code here...

	/*------------------------------------------------------------------
		[ FOOTER ]
	-------------------------------------------------------------------*/
	// code here...
}



@media (min-width: 1400px) {
    .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
        max-width: 1140px !important;
    }
}