/*------------------------------------------------------------------
	[  Header ]
-------------------------------------------------------------------*/
#login{
  .login-title{
    color: #000000;
    font-size: 20px;
    margin-top: 20px;
    margin-bottom: 40px;
  }
  .btn{
    margin-bottom: 0;
  }
  a{
    color: #000;

    &:hover{
      color: $blue;
    }
  }
}


/*-------------------------
    [Main Content]
--------------------------*/
.main-content{
  background-color: #f6f6f6;

  .page-title-section {
    background-color: #fff;
    margin-bottom: 15px;
    padding: 20px 0 0 0;

    h1{
      margin: 0;
      color: #697882;
      font-size: 24px;
    }
  }

  .content-container{
    background-color: #fff;
    padding-top: 20px;
    padding-bottom: 20px;
  }

}

.action-drop-menu{
  li{
    a{
      color: #697882;
      display: block;
      padding: 6px 10px;
      font-family: $font-default;
      font-size: 14px;

      &:hover{
        background-color: #e1e5ec;
        color: #000;
      }
    }
  }
}


/*-------------
  [Search Box]
------------*/
.search-box{
  margin-bottom: 15px;

  .input-group{
    margin-bottom: 0;

    input{
      background-color: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.15);
      color: #000;
      font-family: "Open Sans",sans-serif;
      font-size: 16px;
      font-stretch: normal;
      font-style: normal;
      font-weight: normal;
      letter-spacing: 0;
      line-height: normal;
      padding: 0 20px 3px;
      text-align: left;
      width: 100%;
      border-radius: 0;
      height: 44px;
      max-width: 280px;
    }

    .btn{
      margin-bottom: 0;
    }
  }
  .error{
    color: $error-color;
    font-size: 13px;
  }
}

.ajax-layer{
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  top:0;
  height: 100vh;
  background-color: rgba(0,0,0,0.7);
  z-index: 9;	

  > div{
    z-index: 9;
    color:#fff;
  }
}

.select2-container--default .select2-selection--single {
  border: 1px solid rgba(0, 0, 0, 0.15);
  height: auto;
  padding: 12px;


  .select2-selection__arrow b{
    border:0;
    top:13px;
    left: 0;
    margin: 0;

    &:before {
      content: "\f107";
      display: inline-block;
      font-family: FontAwesome;
      font-style: normal;
      font-weight: 400;
    }
  }
}


#comments-section{
  .comment{
    border-bottom: 1px solid rgba(0,0,0,0.1);
    padding: 15px 0;

    .profile_image_anchor{
      background-color: #ffffff;
      border: 1px solid #efefef;
      border-radius: 100%;
      display: inline-block;
      height: 50px;
      overflow: hidden;
      position: relative;
      width: 50px;

      span{
        display: inline-block;
        height: 100% !important;
        overflow: hidden;
        position: relative;
        width: 100% !important;

        img{
          left: 0;
          margin: 0 auto;
          max-width: 100% !important;
          position: absolute;
          right: 0;
          text-align: center;
          top: 49%;
          -webkit-transform: translateY(-50%);
          -moz-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          -o-transform: translateY(-50%);
          transform: translateY(-50%);
          width: 100% !important;
          z-index: 0;
        }
      }
    }
    h5{
      font-size: 14px;
      color: #060606;
      font-weight: 600;
    }
    .comment-text{
      font-size: 13px;
      color: #373a3c;
    }
    .create_date{
      font-size: 13px;
      color: #373a3c;
    }
  }
}

.caption.caption-md {
  padding: 15px;
}

.dataTables_wrapper {
  border-bottom: 1px solid #e7ecf1;

  .dataTables_processing{
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 30px;
    color: #ffffff
  }

  #contractor_datatable_processing{
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200px;
    margin-left: -100px;
    margin-top: -26px;
    text-align: center;
    padding: 1em 0;
  }

  .dataTables_length {
    float: left;
    width: 50%;
    display: none !important;
  }
  .dataTables_filter {
    float: left;
    width: 50%;

    input {
      border: 1px solid #e7ecf1;
    }
  }

  .dataTable {
    border: 1px solid #e7ecf1;
    border-collapse: collapse !important;

    thead {
      tr {
        border-bottom: 1px solid #e7ecf1;
        th {
          color: #333;
          font-family: $font-default;
          font-size: 11px;
          font-weight: 600;
          padding: 7px;

          strong{
            font-size: 10px !important;
          }

          & + th {
            border-left: 1px solid #e7ecf1;
          }
        }
      }
    }
    tbody {
      tr {
        border-top: 1px solid #e7ecf1;

        td {
          color: #333333;
          font-size: 11px;
          padding: 7px;

          + td {
            border-left: 1px solid #e7ecf1;
          }

          a {
            margin: 0;
          }
        }
      }
    }
  }
  .dataTables_info{
    width: 50%;
    float: left;
    margin-top: 10px;
  }
  .dataTables_paginate{
    width: 50%;
    float: left;
    margin-top: 10px;
  }
}

ul.body_corporate_list{
  li{
    padding: 0 !important;

    a{
      padding: 7px 10px !important;
      display: block;
    }
  }
}
#reports_form_section select {
  min-width: 200px;
}

.notifications-tooltip {
  ul{
    li{
      a{
        font-size: 12px !important;
        line-height: normal;
      }
    }
  }
}

.allNotificationList{
  border: 1px solid #edefed;

  .notify-item{
    border-bottom: 1px solid #edefed;
    padding: 12px 0;

    a{
      font-family: $font-default;
      color: #337ab7;
      font-size: 14px;
      text-align: left;
      &:hover{
        text-decoration: underline !important;
        color: #23527c;
      }

      &.notify-icon{
        padding: 0 10px;
        i{
          font-size: 20px;
        }

        &.notify-success{
          color: #36c6d3;
        }
        &.notify-info{
          color: #659be0;
        }
        &.notify-danger{
          color: #ed6b75;
        }
      }
    }
  }

}

.login-page body .main-content{
  background-color: #fff;
}

#login{
  .field-input{
    input{
      background-color: #f0f4f5;
      color: #000000;
      border: 1px solid #6ba3c8;
      padding: 12px !important;
    }
    //PlaceHolder Styles
    input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: #000000;
    }
    input::-moz-placeholder { /* Firefox 19+ */
      color: #000000;
    }
    input:-ms-input-placeholder { /* IE 10+ */
      color: #000000;
    }
    input:-moz-placeholder { /* Firefox 18- */
      color: #000000;
    }
  }

  a{
    color: #26a1ab !important;
    font-size: 15px;
  }
}

.login-page {
  h4{
    color: #000000;
    font-weight: 300;
    font-size: 19px;
  }
}

.top-head-menu{
  .btn{
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: 1px solid #44b6ae;
    color: #44b6ae !important;
    border-radius: 25px;
    font-size: 12px !important;
    padding: 15px 18px !important;
    font-weight: normal !important;
    text-transform: capitalize;

    i{
      font-size: 12px;
    }
  }
  .dropdown-menu.panel{
    ul li:hover{
      background-color: #f6f6f6;
      a{
        color: #000;
      }
    }
  }

  .notify-icon{
    >a{
      color: #a4b4bb !important;
    }

    .notifications-dropdown{
      background-color: #2e343b;
      border: 1px solid #2e343b;
      &:after{
        -moz-border-bottom-colors: none;
        -moz-border-left-colors: none;
        -moz-border-right-colors: none;
        -moz-border-top-colors: none;
        border-color: currentcolor transparent #fff;
        border-bottom-color: #2e343b;
        border-image: none;
        border-style: none solid solid;
        border-width: 0 9px 9px;
      }
      .panel-header{
        background-color: #272c33;
        color: #a2abb7;

        a{
          color: #337ab7;
        }
      }
      ul{
        background-color: #2e343b;

        li{
          background-color: #2e343b;
          padding: 0 !important;

          &:hover{
            background-color: #373e47 !important;
            a{
              background-color: #373e47 !important;
            }
          }
          a{
            color: #aaafb7 !important;
            padding: 10px 12px !important;
            display: block !important;
            font-size: 13px !important;

            &.notify-success{
              color: #36c6d3 !important;
            }
            &.notify-info{
              color: #659be0 !important;
            }
            &.notify-danger{
              color: #ed6b75 !important;
            }

          }

        }
        li + li{
          border-top: 1px solid #3b434c !important;
        }
      }
    }

  }

  .my-profile-dropdown{
    // background-color: #2e343b;
    // border: 1px solid #2e343b;
    &:after{
      -moz-border-bottom-colors: none;
      -moz-border-left-colors: none;
      -moz-border-right-colors: none;
      -moz-border-top-colors: none;
      border-color: currentcolor transparent #fff;
      border-bottom-color: #2e343b;
      border-image: none;
      border-style: none solid solid;
      border-width: 0 9px 9px;
    }
    ul{
      background-color: #2e343b;

      li{
        background-color: #2e343b;
        padding: 0 !important;

        &:hover{
            a{
              background-color: #373e47 !important;
            }
        }
        a{
          color: #aaafb7 !important;
          padding: 10px 12px !important;
          display: block !important;
          font-size: 13px !important;

          i{
            color: #6fa7d7 !important;
            padding-right: 7px;
          }
        }

      }
      li + li{
        border-top: 1px solid #3b434c !important;
      }
    }
  }
}

.data-table{
  .btn-group{
    .btn-red{
      background-color: #fff;
      color: $red !important;
      border-radius: 25px !important;
    }
  }
}
.dataTables_wrapper .dataTables_filter {
  float: right;
}
.dataTables_wrapper .dataTables_paginate{
  float: right;
  margin-top: 0;
}
.table-container .dataTables_wrapper{
  thead{
    tr th{
      strong{
        font-size: 12px !important;
        text-transform: capitalize !important;
      }
    }
  }

  tbody{
    tr td{
      font-size: 13px !important;
    }
  }
}
.medium_profile_image{
  border-radius: 50%;
  float: none;
  height: auto;
  margin: 0 auto;
  width: 50%;
}