.titan-accordion {
    margin: 0;
    padding: 0;
    background: #2196F3;
}

.titan-accordion__submenu {
    margin: 0;
    padding: 0;	
    .titan-accordion__li { 
        background: #1976D2;
    }
}

.titan-accordion__li { 
    border-top: 1px solid #fff;
    list-style: none;
}

.titan-accordion--child-1 { text-indent: 1em; }
.titan-accordion--child-2 { text-indent: 2em; }
.titan-accordion--child-3 { text-indent: 3em; }

.titan-accordion--clickable {
    /*background: url($img-url + 'accordion-arrow.png') no-repeat 96% 10px;*/
    cursor: pointer;
}

.titan-accordion--shown { 
    /*background: url($img-url + 'accordion-arrow.png') no-repeat 96% -70px;*/
}

.titan-accordion__title { 
    height: 48px;
    line-height: 48px;
    display: block;
}

.titan-accordion__title {
    a {
        color: #fff;
        display: block;
        padding: 0 1em;
        width: 84%;
    }
}

.pagination-item a {
    padding: 6px 16px;
    border: 1px solid #eceeef
}

.pagination-item a:hover {
    background-color: #3598dc;
    border: 1px solid #3598dc;
    color: #ffffff;
    padding: 6px 16px;
}
.pagination-item a:focus {
    z-index: 0;
    box-shadow: none;
}

.page-link:hover{
    background-color: #3598dc !important;
    border: 1px solid #3598dc;
    color: #ffffff !important;
    padding: 6px 16px;
}

.pagination-item.active .page-link{
    padding: 6px 12px;
    background-color: #3598dc !important;
    border-color: #3598dc !important
}

#dropdown-create-basic-button:hover,
#dropdown-create-basic-button:active,
#dropdown-create-basic-button:focus,
#dropdown-create-basic-button:focus-visible
{
    background-color: #26a1ab !important;
    color: #fff !important;
    border: 1px solid #2499a3 !important
}

#dropdown-create-basic-button.btn-light.show{
    color: #fff !important;
    background-color: #26a1ab !important;
    border-color: #26a1ab !important;
}

#dropdown-notifications.btn.btn-link,
#dropdown-notifications:hover
{
    color: #a4b4bb !important;
    font-size: 24px;
}

.no-caret::after {
    display: none !important;
}

#dropdown-profile:hover{
color: none;
}

#dropdown-profile.dropdown-user{
 padding: 0% !important;
}

