@import "../../styles/style.scss";

.custom-width-20 {
    width: 18% !important;
}

.fa-bell.svg-inline--fa {
    height: 1.7em
}

.min-height-5rem {
    height: 5rem
}

.info-message {
    background-color: #2f96b4;
    color: #fff;
    background-repeat: no-repeat;
    background-position-y: center;
    padding: 10px 26px;
}

.custom-width-14rem {
    width: 14rem !important;
}

.btn-inherit-color {
    background-color: inherit !important;
    border-color: inherit !important;

}

.capitalize-text {
    text-transform: capitalize !important;
}

#reports_status label {
    width: 6rem;
}

.report-header-font {
    font-size: 0.8rem;
}

.btn-save-pdf:hover {
    color: #212529 !important;
    background-color: #fff !important;
}

.btn-save-pdf {
    border: solid 1px #212529 !important;
    border-radius: 5px;
}

.processing-report {
    text-align: center;
}

.dataTables_processing-report {
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 5px;
    color: #ffffff;
    padding: 1rem 2rem;
}

.dropdown-menu.notifications-dropdown.panel {
    left: auto;
    line-height: normal;
    max-width: 460px;
    min-width: 282px;
    position: absolute;
    right: 0;
    top: 46px;
    z-index: 3001;
    padding: 0;
    margin: .125rem 0 0;
    font-size: 1rem;
    color: #373a3c;
    text-align: left;
    list-style: none;
    background-clip: padding-box;

}

.notifications-dropdown.panel.tooltip-top-right {
    // background-color: #272c33;
    color: #a2abb7;
}

.notifications-dropdown.panel .panel-header {
    font-size: 13px;
    padding: 15px 12px;
    background-color: #272c33;
    color: #a2abb7;
    position: relative;
    text-align: left;
    font-family: "Open Sans", sans-serif;
}

.notifications-dropdown.panel .panel-header a {
    color: #337ab7;
    font-family: "Open Sans", sans-serif;
    margin-left: 15px;
    text-align: left
}

.notifications-dropdown.panel .panel-body {
    padding: 0 0 2px;
    position: relative;
    margin: 0;
}

.notifications-dropdown.panel .panel-body .panel-body-inner {
    position: relative;
    overflow: hidden;
    width: auto;
    height: 250px;
}

.notifications-dropdown.panel .panel-body .panel-body-inner ul {
    overflow: auto;
    width: auto;
    height: 250px;
    display: block !important;
    position: relative !important;
    padding: 0;
    margin: 0 auto;
    list-style: none;
}

.notifications-dropdown.panel .panel-body .panel-body-inner ul li a {
    color: #aaafb7 !important;
    padding: 10px 12px !important;
    display: block !important;
    font-size: 13px !important;
}

.notifications-dropdown.panel .panel-body .panel-body-inner ul li a.notify-success {
    color: #36c6d3 !important;
}

.notifications-dropdown.panel .panel-body .panel-body-inner ul li a.notify-danger {
    color: #ed6b75 !important;
}

.dropdown-link-anchor {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5rem;
}

.pdf-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.pdf-container .react-pdf__Page__canvas {
    display: block;
    margin: 0 auto;
}