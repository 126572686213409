header{
  background-color: #fff;

  .navbar {
    .fa-bars{
      color:white;
    }

    .navbar-toggle {
      float: none;
    }

    .navbar-nav li{
      span.dropdown-toggle {
        position: relative;
        &:after {
          content: '\35';
          font-size: 30px;
          color:white;
        }
      }
      &.open {
        &>span.dropdown-toggle {
          &:after {
            content: '\33';
          }
        }
      }
      .dropdown-menu {
        background-color: #231f20;
        li:last-child {
          border-bottom: none;
        }
        a {
          border: none;
          &:hover,
          &:active,
          &:focus{
            background-color: inherit;
            border: none;
          }
        }
      }

      a{
        display:inline-block;
      }
    }
  }
}

.header-logo{
    img{
      max-width: 70px;
      height: auto;
    }
}

.top-head-menu{
  ul{
    margin: 0 auto;
    list-style: none;

    li{
      float: left;
      position: relative;

      & +li{
        margin-left: 30px;
      }

      .btn{
        margin-bottom: 0;
        padding: 11px 20px;
        font-size: 12px;

        i{
          margin-left: 7px;
        }
      }
    }
  }

  .notify-icon{
    color: $blue;
    font-size: 22px;
  }
  .dropdown-user{
    border:1px solid #ffffff !important;

    .drop-down-menu{
      min-width: 170px !important;
    }
    .profile_image_anchor{
      background-color: #ffffff;
      border: 1px solid #efefef;
      border-radius: 100%;
      display: inline-block;
      height: 50px;
      overflow: hidden;
      position: relative;
      width: 50px;

      span{
        display: inline-block;
        height: 100% !important;
        overflow: hidden;
        position: relative;
        width: 100% !important;

        img{
          left: 0;
          margin: 0 auto;
          max-width: 100% !important;
          position: absolute;
          right: 0;
          text-align: center;
          top: 49%;
          -webkit-transform: translateY(-50%);
          -moz-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          -o-transform: translateY(-50%);
          transform: translateY(-50%);
          width: 100% !important;
          z-index: 0;
        }
      }
    }

    .profile_name{
      color: $colo-default;
      font-size: 14px;
      margin-left: 10px;
    }
  }
}


.drop-down-menu {
  border-radius: 0 0 2px 2px;
  display: none;
  left: auto;
  line-height: normal;
  max-width: 460px;
  min-width: 282px;
  position: absolute;
  right: 0;
  top: 46px;
  z-index: 3001;
  padding: 0;

  &.panel {
    background-color: #fff;
    border: 1px solid #dce0e0;
    border-radius: 0;

    .panel-header{
      font-size: 13px;
      padding: 15px 12px;
      background-color: #edefed;
      position: relative;
      text-align: left;
      font-family: $font-default;
      color: #212121;

      a{
        font-family: $font-default;
        color: #212121;
        margin-left: 15px;
      }
    }

    .panel-body {
      padding: 0 0 2px;
      position: relative;
      margin: 0;

      ul{
        display: block !important;
        position: relative !important;
        padding: 0;

        li{
          margin: 0 !important;
          padding: 7px 0;
          width: 100%;

          &:hover{
            background-color: $blue;

            a{
              color: #fff;
            }
          }

          > div > *{
            padding: 0 9px;
          }

          & + li{
            border-top: 1px solid #edefed;
          }

          a{
            font-family: $font-default;
            color: #484848;
            font-size: 14px;
            text-align: left;

            &.notify-icon{
              i{
                font-size: 20px;
              }

              &.notify-success{
                color: #36c6d3;
              }
              &.notify-info{
                color: #659be0;
              }
              &.notify-danger{
                color: #ed6b75;
              }
            }
          }
        }
      }
    }

  }
}

.tooltip-top-right{
  &:before{
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    border-color: currentcolor transparent rgba(0, 0, 0, 0.1);
    border-image: none;
    border-style: none solid solid;
    border-width: 0 10px 10px;
    bottom: auto;
    content: "";
    display: inline-block;
    position: absolute;
    right: 14px;
    top: -10px;
  }
  &:after{
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    border-color: currentcolor transparent #fff;
    border-bottom-color: #edefed;
    border-image: none;
    border-style: none solid solid;
    border-width: 0 9px 9px;
    bottom: auto;
    content: "";
    display: inline-block;
    position: absolute;
    right: 15px;
    top: -9px;
  }

}

.main-menu{
  background-color: #1f1f1f;
  padding: 0;

  .navbar {
    margin: 0;
    padding: 0;
    background: none;

    .navbar-toggler {
      float: none;
      display: none;
    }
    .navbar-brand {
      display: none;
    }
    .navbar-collapse {
      display: block;
    }

    .navbar-nav{
      list-style: none;
      float: left;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: row;

      .nav-item + .nav-item{
        margin-left: 0px;
      }

      li {

        span.dropdown-toggle {
          position: relative;
          &:after {
            display: none;
            content: '\35';
            font-size: 30px;
            font-family: 'ElegantIcons';
            color: #212121;
            border: 0;
          }
        }
        &.open {
          & > span.dropdown-toggle {
            &:after {
              content: '\33';
            }
          }
        }
        .dropdown-menu {
          background-color: #231f20;
          li:last-child {
            border-bottom: none;
          }
          a {
            border: none;
            &:hover,
            &:active,
            &:focus {
              background-color: inherit;
              border: none;
            }
          }
        }

        a {
          display: inline-block;
          font-family: $font-default;
          font-weight: 400;
          font-size: 14px;
          color: #bcc2cb;
          text-transform: capitalize;
          letter-spacing: normal;
          padding: 16px 18px 15px;
        }
        &.active > a, &:hover > a{
          background-color: #4e5966;
          color: #f1f1f1 !important;
        }
      }
    }
  }
}


#searchForm{
  .form-field{
    float: left;
    width: 50%;
    margin-bottom: 0;

    .field-input{
      margin-bottom: 0;

      select{
        background-color: #1f1f1f;
        color: #fff;
        font-size: 14px;
        padding: 15px 10px;
        //border-top: 1px solid #000;
        //border-bottom: 1px solid #000;
        //border-left: 0;
        //border-right: 0;
        border: 0;
      }
      input{
        background-color: #1f1f1f;
        color: #fff;
        //border-top: 1px solid #000;
        //border-bottom: 1px solid #000;
        //border-left: 1px solid #ccc;
        border: 0;
        border-right: 0;
        font-size: 14px;
        padding: 16px 7px;
      }
    }
  }
}