@import url('https://fonts.googleapis.com/css?family=Roboto');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700,800');


@font-face {
  font-family: 'Glyphicons Halflings';
  src: url('../../assets/fonts/glyphicons/glyphicons-halflings-regular.eot');
  src: url('../../assets/fonts/glyphicons/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'),
       url('../../assets/fonts/glyphicons/glyphicons-halflings-regular.woff2') format('woff2'),
       url('../../assets/fonts/glyphicons/glyphicons-halflings-regular.woff') format('woff'),
       url('../../assets/fonts/glyphicons/glyphicons-halflings-regular.ttf') format('truetype'),
       url('../../assets/fonts/glyphicons/glyphicons-halflings-regular.svg#glyphicons_halflingsregular') format('svg');
}



//@font-face {
//  font-family: 'Font-awesome';
//  src: url('../libs/font-awesome/fonts/fontawesome-webfont.eot?#iefix') format('embedded-opentype'),  url('../fonts/fontawesome-webfont.woff') format('woff'), url('../fonts/fontawesome-webfont.ttf')  format('truetype'), url('../fonts/fontawesome-webfont.svg#font-awesome') format('svg');
//  font-weight: normal;
//  font-style: normal;
//}


/* MEDIA QUERIES SCREEN SIZES */
$xsmall-devices		: 480px;
$small-devices		: 767px;
$medium-devices		: 991px;
$large-devices		: 1199px;
$xlarge-devices		: 1280px;

/* URL's */
$img-url			: '../images/';

/* Fonts / Sizes */
$font-default		      : 'Open Sans', sans-serif;
$font-roboto              : 'Roboto';


/* Primary COLORS */
$colo-default		: #21416b;
$dark-gray			: #fdfdfd;
$blue               : #3598dc;
$sky-blue           : #32c5d2;
$powder-blue        : #26a1ab;
$gold	            : #a48334;
$red                : #e12330;

/* Status Message COLORS */
$error-color		: #ed171f;
$success-color      : #169207;


$enable-flex: true;

/* Global Styles */
html{ 
	overflow-y: scroll;	
}
body{
    background-color: #f6f6f6;
	font-family: $font-default !important;
}
*{
    outline: none !important;
}
.container .row {
  margin-left: -15px !important;
  margin-right: -15px !important;
}

.container .row.margin-left-rm {
  margin-left: 0px !important;
}

a{
    text-decoration: none !important;
}
.clearBoth{
    clear: both;
    float: none;
}
h1{
    color: $colo-default ;
    font-family: $font-default;
    font-size: 32px;
    margin-bottom: 10px;
    margin-top: 36px;
    text-transform: capitalize;
}
h2{
    color: $colo-default ;
    font-family: $font-default;
    font-size: 28px;
    margin-bottom: 20px;
}
h3{
    color: $colo-default ;
    font-family: $font-default;
    font-size: 24px;
    margin-bottom: 20px;
}
h4{
    color: $colo-default ;
    font-family: $font-default;
    font-size: 20px;
    margin-bottom: 20px;
}
h5{
    color: $colo-default ;
    font-family: $font-default;
    font-size: 18px;
    margin-bottom: 20px; 
}
h6{
    color: $colo-default ;
    font-family: $font-default;
    font-size: 16px;
    margin-bottom: 20px; 
}

.page__content{   
    
    >p, >h2, >h3, >h4, >h5, >h6{
        margin-top: 0;
    }
    p{
        color: $colo-default;
        font-family: $font-default;
        font-size: 12px;
        line-height: 20px;
        margin-bottom: 19px;
        
        a{
            color: $blue;
        }
    } 
    strong{
        color: $dark-gray;
    }
    .post__featured{
        margin-bottom: 0;
    }
    
    /* Featured Image*/
    
    img{
        float: left;
        margin-bottom: 20px;
        margin-right: 30px;
        margin-top: 4px;
        max-width: 100%;
        height: auto;
    }
    
    /* Order/ UnOrder list*/
    >ul{
        margin-left: 95px;
        margin-bottom: 20px;        
        
    }
    
    ul{
        overflow: hidden;
        list-style: none;
        >li{
            color: $colo-default;
            font-family: $font-default;
            font-size: 12px;
            margin-top: 14px;
            margin-bottom: 0;
            
            &:before{
                color: $dark-gray;
                content: "\f101";
                font-family: FontAwesome;
                margin-left: -38px;
                margin-right: 5px;
            }
            
            a{
                color: $blue;
                text-decoration: none;
            }
            
            &:first-child{
                margin-top: 0;
            }
        }
        
        
    }
    
    ol{ 
        @extend ul;
        list-style: outside decimal;
        
        >li{
            &:before{
                content:"";
                margin: 0;
            }
        }
        ol{
            list-style: outside lower-alpha;
            padding-left: 20px;            
            
        }
    }
    
    .attachment-contact-thumb{
        float: none;
        margin-right: 0;
    }
    
}

.btn{
	// margin-bottom: 18px;
	font-family: $font-default !important;
    font-weight: 700 !important;
    color: #000 !important;
    border: 1px solid $blue;
    padding: 12px 20px !important;
    border-radius: 0 ;
    text-transform: uppercase !important;
    text-align: center !important;
    font-size: 12px !important;

    i{
      font-size: 16px;

      &.icon-right{
        margin-left: 7px;
      }
      &.icon-left{
        margin-right: 7px;
      }

    }

    &:hover{
      background-color: $blue;
      color: #fff !important;
    }

  &.btn-blue{
    background-color: $blue;
    color: #fff !important;
    border: 1px solid $blue;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: normal;

    &:hover{
      background-color: #217ebd;
      border: 1px solid #1f78b5;
      color: #fff !important;
    }
  }
  &.btn-light-blue{
    padding: 10px 25px;
    background-color: $sky-blue;
    color: #fff !important;
    border: 1px solid #32c5d2;
    font-size: 14px;

    &:hover{
      background-color: #26a1ab;
      color: #fff !important;
      border: 1px solid #2499a3 !important;
    }
  }
  &.btn-bc{
    padding: 10px 16px !important;
    background-color: #67809f;
    color: #fff !important;
    border: 1px solid #67809f;
    font-size: 18px !important;
    display: block;
    width: 100%;
    font-weight: normal !important;
    margin-left: 0 !important;
    margin-bottom: 5px !important;
    text-transform: capitalize;

    &:hover{
      background-color: #526781;
      color: #fff !important;
      border: 1px solid #4e627b !important;
    }
  }
  &.btn-light-blue-large{
    padding: 12px 20px;
    background-color: $sky-blue;
    color: #fff !important;
    border: 1px solid #32c5d2;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: normal;

    &:hover{
      background-color: #26a1ab;
      color: #fff !important;
      border: 1px solid #2499a3 !important;
    }
  }

  &.btn-red{
    background-color: $red;
    color: #fff !important;
    border: 1px solid red !important;

    &:hover{
      background-color: #fff;
      color: $red !important;
    }
  }
  &.btn-gold{
    background-color: $gold;
    color: #fff !important;
    border: 1px solid $gold !important;
    text-transform: capitalize;
    font-size: 14px;
    font-weight: normal;

    &:hover{
      background-color: #fff;
      color: $gold !important;
    }
  }

}
.btn + .btn{
	margin-left: 15px;
}

button + button {
    margin-left: 15px;
}


.cyx-breadcrumb{
    background: none;
    margin-top: -11px;
    margin-bottom: 2px;
    padding-left: 0;
    color: $font-default;
    font-family: $font-default;
    font-weight: bold;
    font-size: 15px;
    
    i{
        margin: 0 2px;
        color: $blue;
    }
    
    span{
        color: $font-default;
        font-family: $font-default;
        font-size: 15px;
        margin-left: 1px;
        margin-right: 3px;
        
       
    }
    a {
        color: $blue;
        font-family: $font-default;
        font-size: 15px;
        
        span{
            color: $blue;
            font-family: $font-default;
            font-size: 15px;
        }
    }
}

.pagination{
  text-align: center;
  display: table;
  margin: 15px auto;

  .page-numbers{
    color: #212121;
    background: none;
    border: 1px solid #e6e6e6;
    display: table-cell;
    font-family: $font-default;
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    border-right: 1px solid #eceeef;
    border-top: 1px solid #eceeef;
    border-bottom: 1px solid #eceeef;
    border-left: 0;
    cursor: pointer;

    a{
      display: block;
      padding: 6px 16px;
    }

    &:first-child{
      border-radius: 4px 0 0 4px;
    }
    &:last-child{
      border-radius: 0 4px 4px 0;
    }

    &::after, &::before{
      display: none !important;
      width: 0;
    }

    &.prev{
      border-left: 1px solid #eceeef;
      border-radius: 4px 0 0 4px;

    }
    &.next{
      border-radius: 0 4px 4px 0;
    }

    &.current{
      background-color: $blue;
      border: 1px solid $blue;
      color: #ffffff;
      padding: 6px 16px;

      &:after, &:before {
        display: none !important;
      }
    }

    &:hover{
      background-color: $blue;
      border-color:$blue;
      color: #ffffff;

      a{
        color: #fff;
      }
    }
  }
}

.dataTables_paginate{
  text-align: center;
  display: table;
  margin-top: 15px !important;

  span{
    display: inline-block;
  }

  .paginate_button{
    color: #212121;
    background: none;
    border: 1px solid #e6e6e6;
    display: inline-block;
    font-family: $font-default;
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    border-right: 1px solid #eceeef;
    border-top: 1px solid #eceeef;
    border-bottom: 1px solid #eceeef;
    border-left: 0;
    cursor: pointer;
    padding: 6px 16px;


    &.current, &:hover, &.active{
      background-color: $blue;
      border: 1px solid $blue;
      color: #ffffff;
      padding: 6px 16px;

      a{
        color: #fff;
      }
    }

    &.first, &.previous{
      border-left: 1px solid #eceeef;
      border-radius: 4px 0 0 4px;

    }
    &.last, &.next{
      border-radius: 0 4px 4px 0;
    }
  }
}

.full-height-viewport{
	height: 100vh;
    min-height: 600px;
}

/*---------------------------------------------
     [System messages]
----------------------------------------------*/
.cl-system-messages{
	font-size: 14px;
	font-weight: normal;
}
.cl-alert {
	background-color: #e1f2fb;
	background-position: 15px center;
	background-repeat: no-repeat;
	border-radius: 2px;
	color: #fff;
	margin-bottom: 20px;
	padding: 10px 10px 10px 50px;
	text-align: left;
}
.error-message {
    background-color: $error-color;
    color: #fff;
    text-align: center;
    font-weight: normal;
    font-size: 15px;
    margin: 10px 0;
    padding: 10px;
}
.success-message {
    background-color: $success-color;
	border: solid 1px #d0e9c6;
	text-align: center;
  	color: #fff;
	font-weight: normal;
	font-size: 15px;
	margin: 10px 0;
	padding: 10px;
}
.warning-message {
    background-color: #f89406;	
	text-align: center;
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=") !important;
    color: #fff;
}
.info-message {
    background-color: #2f96b4;
	background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=") !important;
    color: #fff;
}


/*---------------------------------------------
   [Animations]
--------------------------------------------*/
@keyframes cl-shake {
	0%, 100% {
		transform: translateX(0px);
	}
	10% {
		transform: translateX(-9px);
	}
	20% {
		transform: translateX(8px);
	}
	30% {
		transform: translateX(-7px);
	}
	40% {
		transform: translateX(6px);
	}
	50% {
		transform: translateX(-5px);
	}
	60% {
		transform: translateX(4px);
	}
	70% {
		transform: translateX(-3px);
	}
	80% {
		transform: translateX(2px);
	}
	90% {
		transform: translateX(-1px);
	}
}
[class*="clss-animation-"] {
    animation-duration: 0.5s;
    animation-fill-mode: both;
    animation-timing-function: ease-out;
}

.cl-animation-shake {
    animation-name: uk-shake;
}

.waves-effect {
  position: relative;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  vertical-align: middle;
  z-index: 1;
  will-change: opacity, transform;
  transition: .3s ease-out;
}

/*------------------
    [ Center OR Middle Bootstrap Columns ]
--------------------------*/
.cl-flex{
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
.cl-flex-center{
  justify-content: center;
}
.cl-flex-middle{
  -ms-flex-align: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
}

/*-------------------------
   Form Validations
---------------------------*/
.form-validaion{
	label.error{
		color: $error-color;
		font-family: $font-default;
		font-size: 12px;
		line-height: normal;
		padding: 0 15px;
	}
}

//Switch Button
.switch {
  position: relative;
  display: inline-block;
  width: 80px;
  height: 30px;

  &:before {
    color: #fff;
    content: "Yes";
    font-size: 13px;
    left: -12px;
    position: relative;
    top: 5px;
    width: 10px;
    z-index: 9;
    cursor: pointer;
  }
  &:after {
    color: #fff;
    content: "No";
    font-size: 13px;
    left: 12px;
    position: relative;
    top: 5px;
    width: 10px;
    cursor: pointer;
  }

  input {
    display:none;

    &:checked + .slider {
      background-color: #a6e50f;
    }
    &:focus + .slider {
      box-shadow: 0 0 1px #a6e50f;
    }
    &:checked + .slider:before {
      -webkit-transform: translateX(50px);
      -ms-transform: translateX(50px);
      transform: translateX(50px);
    }
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #939ea7;
    -webkit-transition: .4s;
    transition: .4s;

    &:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 2px;
      bottom: 2px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }
    &.round {
      border-radius: 34px;

      &:before {
        border-radius: 50%;
      }
    }
  }
}


/*--------------------------
   [ Default Form ]
----------------------------*/

form{
  .field-section{
    + .field-section{
      margin-top: 60px;
    }
    .field-section-title{
      font-family: $font-default;
      font-size: 20px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0;
      text-align: left;
      color: #162944;
      margin-bottom: 16px;
    }
  }
  .form-field{
    margin-bottom: 20px;

    //Error
    .error{
      color: $red;
      font-family: $colo-default;
      font-size: 13px;
      margin-bottom: 0;
    }
    ul {
      padding: 0;
      li{
        padding: 0;
        list-style: none;
        color: $red;
        font-family: $colo-default;
        font-size: 13px;
        margin-bottom: 0;
      }
    }

    #RegisterCaptcha_CaptchaDiv{
      background-color: #ffffff;
      border: 1px solid rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      display: block;
      height: 75px !important;
      margin-bottom: 10px !important;
      padding: 12px 20px !important;
      width: 100% !important;
    }
      .field-label{
          color: $blue;
          font-family: $font-default;
          font-size: 16px;
          font-weight: 600;
          text-transform: capitalize;
          margin-bottom: 7px;
      }
      .field-input{

          //Input Box
          input[type=text], input[type=number], input[type=email], input[type=password], input[type=url]{
            background-color: #ffffff;
            border: solid 1px rgba(0,0,0,0.15);

            font-family: $font-default;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 0;
            text-align: left;
            color: #000;
            padding: 12px 20px;
            width: 100%;

            &:focus{
              border-color: $blue;
            }
          }

          //Select Box
          select{
            background-color: #ffffff;
            border: solid 1px rgba(0,0,0,0.15);
            font-family: $font-default;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 0;
            text-align: left;
            color: #000;
            padding: 5px 20px;
            width: 100%;
            min-height: 50px;
          }
          //Text Area
          textarea{
            background-color: #ffffff;
            border: solid 1px #e5e5e5 !important;
            font-family: $font-default;
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: 0;
            text-align: left;
            color: #000;
            padding: 12px 20px;
            width: 100%;
            resize: vertical;
          }
          //Radio Button
            [type="radio"]:not(:checked),
            [type="radio"]:checked {
              position: absolute;
              left: -9999px;
              outline: none;
            }
            [type="radio"]:not(:checked) + label,
            [type="radio"]:checked + label {
              position: relative;
              padding-left: 28px;
              cursor: pointer;
              color: #212121;
              padding-top: 3px;
              outline: none;

            }

            /* checkbox aspect */
            [type="radio"]:not(:checked) + label:before,
            [type="radio"]:checked + label:before {
              content: '';
              position: absolute;
              left:0;
              top: 4px;
              width: 22px;
              height: 22px;
              border: 1px solid #bbb;
              border-radius: 100%;
              background: #fff;
              box-shadow: inset 0 1px 3px rgba(0,0,0,.1);
              outline: none;

            }
            /* checked mark aspect */
            [type="radio"]:not(:checked) + label:after,
            [type="radio"]:checked + label:after {
              content: '\2022';
              position: absolute;
              line-height: 0.8;
              color: $blue;
              transition: all .2s;
              outline: none;
              top: -2px;
              left: 1.5px;
              font-size: 35px;
              outline: none;
            }
            /* checked mark aspect changes */
            [type="radio"]:not(:checked) + label:after {
              opacity: 0;
              transform: scale(0);
            }
            [type="radio"]:checked + label:after {
              opacity: 1;
              transform: scale(1);
            }
            /* disabled checkbox */
            [type="radio"]:disabled:not(:checked) + label:before,
            [type="radio"]:disabled:checked + label:before {
              box-shadow: none;
              border-color: #bbb;
              background-color: #ddd;
              outline: none;
            }
            [type="radio"]:disabled:checked + label:after {
              color: #999;
              outline: none;
            }
            [type="radio"]:disabled + label {
              color: #aaa;
              outline: none;
            }
            /* accessibility */
            [type="radio"]:checked:focus + label:before,
            [type="radio"]:not(:checked):focus + label:before {
              border: 1px dotted $blue;
            }
            [type="radio"] + label {
              display: block;
            }

          /////Checkbox
          [type="checkbox"]:not(:checked),
          [type="checkbox"]:checked {
            position: absolute;
            left: -9999px;
            outline: none;
          }
          [type="checkbox"]:not(:checked) + label,
          [type="checkbox"]:checked + label {
            position: relative;
            padding-left: 28px;
            cursor: pointer;
            color: #212121;
            padding-top: 3px;
            outline: none;

          }

          /* checkbox aspect */
          [type="checkbox"]:not(:checked) + label:before,
          [type="checkbox"]:checked + label:before {
            content: '';
            position: absolute;
            left:0;
            top: 4px;
            width: 22px;
            height: 22px;
            border: 1px solid #bbb;
            border-radius: 0;
            background: #fff;
            box-shadow: inset 0 1px 3px rgba(0,0,0,.1);
            outline: none;

          }
          & {
            content: ':heavy_check_mark:';
          }
          /* checked mark aspect */
          [type="checkbox"]:not(:checked) + label:after,
          [type="checkbox"]:checked + label:after {
            content: '\2714';
            position: absolute;
            line-height: 0.8;
            color: $blue;
            transition: all .2s;
            outline: none;
            top: 10px;
            left: 4px;
            font-size: 14px;
            outline: none;
            font-family: FontAwesome;
          }
          /* checked mark aspect changes */
          [type="checkbox"]:not(:checked) + label:after {
            opacity: 0;
            transform: scale(0);
          }
          [type="checkbox"]:checked + label:after {
            opacity: 1;
            transform: scale(1);
          }
          /* disabled checkbox */
          [type="checkbox"]:disabled:not(:checked) + label:before,
          [type="checkbox"]:disabled:checked + label:before {
            box-shadow: none;
            border-color: #bbb;
            background-color: #ddd;
            outline: none;
          }
          [type="checkbox"]:disabled:checked + label:after {
            color: #999;
            outline: none;
          }
          [type="checkbox"]:disabled + label {
            color: #aaa;
            outline: none;
          }
          /* accessibility */
          [type="checkbox"]:checked:focus + label:before,
          [type="checkbox"]:not(:checked):focus + label:before {
            border: 1px dotted $blue;
          }
          [type="checkbox"] + label {
            display: block;
          }

          .inputfile {
            height: 0.1px;
            opacity: 0 !important;
            overflow: hidden;
            position: absolute;
            width: 0.1px !important;
            z-index: -1;
          }

          .input-group-addon{
            border-radius: 0 !important;
            font-size: 20px;
            padding: 12px 15px;
            background-color: $blue;
            color: #fff;
            border-color: $blue;
            border-right: 0;
          }

      }

  }

  //PlaceHolder Styles
  input::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #b5b5b5;
  }
  input::-moz-placeholder { /* Firefox 19+ */
    color: #b5b5b5;
  }
  input:-ms-input-placeholder { /* IE 10+ */
    color: #b5b5b5;
  }
  input:-moz-placeholder { /* Firefox 18- */
    color: #b5b5b5;
  }

  //Turn Off Number Input Spinners
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
}


/*------------------------
    [ Page Breadcrumb]
-------------------------*/
.page-breadcrumb{
  list-style: none;
  padding: 0;
  margin: 10px 0 0;

  li{
    float: left;
    font-size: 14px;

    a{
      color: $blue;
    }

    i{
      font-size: 12px;
      margin-right: 5px;
    }
  }
}

/*------------------------
    [ Tabs]
-------------------------*/
.content-tabs{
  .nav-tabs{
    margin-bottom: 15px;

    li.nav-item{
      border-radius: 0;
      border: 0;
      margin: 0;

      a{
        color: #697882;
        font-size: 14px;
        border-width: 0 0 3px 0;
        border-color: #fff;

        &.active, &:hover{
          border-color: #36c6d3;
          border-radius: 0;
          color: #333333;
        }
      }

    }
  }
}

/*------------------------
    [ Table]
-------------------------*/

.data-table{
  border: 1px solid #e7ecf1;
  width: 100%;

  thead{
    tr{
      border-bottom: 1px solid #e7ecf1;

      th{
        font-family: $font-default;
        font-size: 11px;
        font-weight: 600;
        color: #000;
        padding: 7px;
        text-transform: capitalize;

        + th{
          border-left: 1px solid #e7ecf1;
        }
      }
    }
  }
  tbody{
    tr{
      + tr{
        border-top: 1px solid #e7ecf1;
      }
      td{
        color: #333;
        font-size: 11px;
        padding: 7px 7px;

        + td{
          border-left: 1px solid #e7ecf1;
        }

        .btn{
          font-weight: 400;
          min-width: 55px;
          padding: 8px 21px;
          text-transform: capitalize;
          margin-bottom: 0;
        }
      }
    }
  }

}


.bootbox{
    .modal-header{
        .modal-title{
          color: #373a3c;
          font-size: 16px;
        }
    }
    .bootbox-body{
      color: #373a3c;
      font-size: 16px;
    }
    .modal-footer{
        .btn-primary{
          color: #fff;
        }
    }
}